import * as React from 'react';
import "./NavigationButton.css";

export default function NavigationButton(props: {icon?: React.ReactElement, hideOnMobile?: boolean, text: string, selected?: boolean, callback?: () => void, onClick?: () => void, smallText?: boolean}) {
  return (
    <div onClick={() => { 
        if (props.callback) props.callback();
        if (props.onClick) props.onClick();
      }} className={`navigationButton ${props.selected ? "selected" : ""} ${props.hideOnMobile ? "hideOnMobile" : ""}`}>
      {props.icon}
      {props.text !== "" && <p className={`iconText ${props.smallText ? "smallText" : ""}`}>{props.text}</p>}
    </div>
  );
}