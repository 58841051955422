import * as React from 'react';
import HomeIcon from '@mui/icons-material/Home';
import NavigationButton from './NavigationButton';
import ThreeSixtyIcon from "@mui/icons-material/ThreeSixty";
import OpenWithIcon from "@mui/icons-material/OpenWith";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import FullScreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import { MOUSE } from 'three';
import CameraControls from 'camera-controls';
import VisibilityIcon from "@mui/icons-material/Visibility";
import KeyboardCapslockIcon from '@mui/icons-material/KeyboardCapslock';
import LayerButton from './LayerButton';
import ElevationButton from './ElevationButton';

import "./Tools.css";
import "./NavigationButton.css";
import { t } from './Translation';
import { Views } from './App';

function Spacer() {
  return (
    <div className="spacer">
      <div></div>
    </div>
  )
} 

export default function Tools(props: {
    controls: CameraControls | null,
    onElevation: (side: string) => void,
    onZoomStart: () => void,
    onChangeLayer: (layer: number[], value: boolean, index: number) => void,
    layer: boolean[],
    expandedPanel: string,
    setExpandedPanel: (value: string) => void,
    checked: boolean[],
    trans: Object,
    views: Views,
  }) {
  const [controlItem, setControlItem] = React.useState(MOUSE.ROTATE);

  function changeControls(mainControl: MOUSE) {
    if (props.controls != null) {
      switch (mainControl) {
        case MOUSE.ROTATE: 
          props.controls.mouseButtons.left = CameraControls.ACTION.ROTATE;
          props.controls.mouseButtons.right = CameraControls.ACTION.OFFSET;
          props.controls.mouseButtons.wheel = CameraControls.ACTION.DOLLY;
          props.controls.touches.one = CameraControls.ACTION.TOUCH_ROTATE;
          break;
        case MOUSE.PAN:
          props.controls.mouseButtons.left = CameraControls.ACTION.OFFSET;
          props.controls.mouseButtons.right = CameraControls.ACTION.ROTATE;
          props.controls.mouseButtons.wheel = CameraControls.ACTION.DOLLY;
          props.controls.touches.one = CameraControls.ACTION.TOUCH_OFFSET;
          break;
        case MOUSE.DOLLY:
          props.controls.mouseButtons.left = CameraControls.ACTION.DOLLY;
          props.controls.mouseButtons.right = CameraControls.ACTION.ROTATE;
          props.controls.mouseButtons.wheel = CameraControls.ACTION.OFFSET;
          break;
      }
    }
  }

  function onGroupSelectionChange(mouse: MOUSE) {
    setControlItem(mouse);
    changeControls(mouse);
  }

  return (
    <footer>
      <NavigationButton
        icon={<HomeIcon/>}
        text={t(props.trans, "tools_home")}
        callback={props.onZoomStart}
        onClick={() => props.setExpandedPanel("none")}
      />
      <ElevationButton
        icon={<KeyboardCapslockIcon/>}
        text={t(props.trans, "tools_views")}
        callback={props.onElevation}
        expandedPanel={props.expandedPanel}
        setExpandedPanel={props.setExpandedPanel}
        trans={props.trans}
        views={props.views}
      />
      <Spacer/>
      <NavigationButton
        icon={<ThreeSixtyIcon/>}
        text={t(props.trans, "tools_rotate")}
        selected={controlItem === MOUSE.ROTATE}
        callback={() => onGroupSelectionChange(MOUSE.ROTATE)}
        onClick={() => props.setExpandedPanel("none")}
      />
      <NavigationButton
        icon={<OpenWithIcon/>}
        text={t(props.trans, "tools_pan")}
        selected={controlItem === MOUSE.PAN}
        callback={() => onGroupSelectionChange(MOUSE.PAN)}
        onClick={() => props.setExpandedPanel("none")}
      />
      <NavigationButton
        hideOnMobile={true}
        icon={<ZoomInIcon/>}
        text={t(props.trans, "tools_zoom")}
        selected={controlItem === MOUSE.DOLLY}
        callback={() => onGroupSelectionChange(MOUSE.DOLLY)}
        onClick={() => props.setExpandedPanel("none")}
      />
      <Spacer/>
      <LayerButton
        icon={<VisibilityIcon/>}
        text={t(props.trans, "tools_visibility")}
        callback={props.onChangeLayer}
        layer={props.layer}
        expandedPanel={props.expandedPanel}
        setExpandedPanel={props.setExpandedPanel}
        checked={props.checked}
        lang={props.trans}
      />
      <Spacer/>
      <NavigationButton
        icon={document.fullscreenElement ? <FullscreenExitIcon/> : <FullScreenIcon/>}
        text={t(props.trans, "tools_fullscreen")}
        selected={false}
        onClick={() => {if (!document.fullscreenElement) document.documentElement.requestFullscreen(); else document.exitFullscreen(); }}
      />
    </footer>
  );
}