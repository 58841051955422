import HomeIcon from '@mui/icons-material/Home';
import ThreeSixtyIcon from "@mui/icons-material/ThreeSixty";
import OpenWithIcon from "@mui/icons-material/OpenWith";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import VisibilityIcon from "@mui/icons-material/Visibility";
import KeyboardCapslockIcon from '@mui/icons-material/KeyboardCapslock';
import FullScreenIcon from "@mui/icons-material/Fullscreen";
import React from 'react';
import './Help.css';
import { t } from './Translation';

function HelpItem(props: {icon: React.ReactElement, children?: React.ReactElement}) {
  return (
    <div className='helpContainer'>
      <div className='icon'>
        {props.icon}
      </div>
      <div className='text'>
        {props.children}
      </div>
    </div>
  );
}

export default function Help(props: {visible: boolean, setOverlay: (value: string) => void, trans: any}) {
  if (!props.visible) { return null }
  return (
  <div className={`overlay`}>
    <div className="overlayContent helpContent">
      <button className="close" onClick={() => props.setOverlay("none")}>close</button>
      <h3>{t(props.trans, "help_title")}</h3>
      <h4>{t(props.trans, "help_views")}</h4>
      <HelpItem icon={<HomeIcon fontSize="large"/>}>
        <p>{t(props.trans, "help_home")}</p>
      </HelpItem>
      <HelpItem icon={<KeyboardCapslockIcon fontSize="large"/>}>
        <p>{t(props.trans, "help_view")}</p>
      </HelpItem>
      <h4>{t(props.trans, "help_navigation")}</h4>
      <p>{t(props.trans, "help_navigationDesc")}</p>
      <HelpItem icon={<ThreeSixtyIcon fontSize="large"/>}>
        <p>{t(props.trans, "help_rotate")}</p>
      </HelpItem>
      <HelpItem icon={<OpenWithIcon fontSize="large"/>}>
        <p>{t(props.trans, "help_pan")}</p>
      </HelpItem>
      <HelpItem icon={<ZoomInIcon fontSize="large"/>}>
        <p>{t(props.trans, "help_zoom")}</p>
      </HelpItem>
      <h4>{t(props.trans, "help_visibility")}</h4>
      <HelpItem icon={<VisibilityIcon fontSize="large"/>}>
        <p>{t(props.trans, "help_visibility2")}</p>
      </HelpItem>
      <HelpItem icon={<FullScreenIcon fontSize="large"/>}>
        <p>{t(props.trans, "help_fullscreen")}</p>
      </HelpItem>
    </div>
  </div>
  );
}