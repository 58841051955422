import { BufferGeometry, Float32BufferAttribute } from "three";

export const SkyBoxGeometry = (size: number) => {
  const s = size / 2
  const geometry = new BufferGeometry();
  const position = new Float32BufferAttribute([
    // ny
    s, -s, -s, // 0
    -s, -s, s, // 2
    s, -s, s, // 1
    //
    s, -s, -s, // 0
    -s, -s, -s, // 3
    -s, -s, s, // 2
    // py
    s, s, -s, // 4
    s, s, s, // 5
    -s, s, s, // 6
    //
    s, s, -s, // 4
    -s, s, s, // 6
    -s, s, -s, // 7
    // px
    s, -s, -s, // 0
    s, -s, s, // 1
    s, s, s, // 5
    //
    s, -s, -s, // 0
    s, s, s, // 5
    s, s, -s, // 4
    // pz
    s, -s, s, // 1
    -s, -s, s, // 2
    -s, s, s, // 6
    //
    s, -s, s, // 1
    -s, s, s, // 6
    s, s, s, // 5
    // nx
    -s, -s, -s, // 3
    -s, s, -s, // 7
    -s, s, s, // 6
    //
    -s, -s, -s, // 3
    -s, s, s, // 6
    -s, -s, s, // 2
    // nz
    s, -s, -s, // 0
    s, s, -s, // 4
    -s, s, -s, // 7
    //
    s, -s, -s, // 0
    -s, s, -s, // 7
    -s, -s, -s, // 3
  ], 3);
  const uv = new Float32BufferAttribute([
    5/6, 1, 1, 0, 5/6, 0, // ny
    5/6, 1, 1, 1, 1, 0,
    5/6, 1, 5/6, 0, 2/3, 0, // py
    5/6, 1, 2/3, 0, 2/3, 1,
    1/6, 1, 1/6, 0, 0, 0, // px
    1/6, 1, 0, 0, 0, 1,
    2/3, 0, 0.5, 0, 0.5, 1, // pz
    2/3, 0, 0.5, 1, 2/3, 1,
    1/6, 1, 1/3, 1, 1/3, 0, // nx
    1/6, 1, 1/3, 0, 1/6, 0,
    0.5, 1, 0.5, 0, 1/3, 0, // nz
    0.5, 1, 1/3, 0, 1/3, 1,
  ], 2);
  geometry.setAttribute('position', position);
  geometry.setAttribute('uv', uv);
  return geometry;
}