import * as React from 'react';
import "./NavigationButton.css";
import "./LayerButton.css";
import { t } from './Translation';

function VisibilityGroup(props: {name: string, id: string, layer: number[], index: number, checked: boolean, callback: (layer: number[], value: boolean, index: number) => void, visibleLayer: boolean[], children?: React.ReactElement[]}) {
  const [checked, setChecked] = React.useState(props.checked);
  let visible = false;
  for (const l of props.layer) {
    if (props.visibleLayer[l]) {
      visible = true;
      break;
    }
  }
  if (!visible) { return null }
  return (
    <div className="groupContainer">
      <div className="groupLeft">
        <input checked={checked} onChange={(e) => {
          setChecked(e.target.checked);
          if (props.callback) { props.callback(props.layer, checked, props.index) }
        }} id={props.id} type="checkbox"/>
        <label htmlFor={props.id}>{props.name}</label>
      </div>
      <div className="groupRight">
        {!checked && <div className="readonly"></div>}
        {props.children}
      </div>
    </div>
  );
}

function VisibilityElement(props: {name: string, id: string, index: number, layer: number, checked: boolean, callback: (layer: number[], value: boolean, index: number) => void, visibleLayer: boolean[],}) {
  const [checked, setChecked] = React.useState(props.checked);
  if (!props.visibleLayer[props.layer]) { return null }
  return (
  <div>
    <input checked={checked} id={props.id} type="checkbox" onChange={(e) => {
      setChecked(e.target.checked);
      if (props.callback) { props.callback([props.layer], checked, props.index) }
    }}/>
    <label htmlFor={props.id}>{props.name}</label>
  </div>
  );
}

export default function LayerButton(props: {icon?: React.ReactElement, text: string, selected?: boolean, callback: (layer: number[], value: boolean, index: number) => void, layer: boolean[], expandedPanel: string, setExpandedPanel: (panel: string) => void, checked: boolean[], lang: Object}) {
  return (
    <div onClick={() => props.setExpandedPanel(props.expandedPanel === "layer" ? "none" : "layer")} className={`navigationButton ${props.selected ? "selected" : ""} ${props.expandedPanel === "layer" ? "expanded" : ""}`}>
      {props.icon}
      <p className="iconText">{props.text}</p>
      {props.expandedPanel === "layer" && <div className='expandContainer'>
        <div onClick={(e) => e.stopPropagation()}>
          <VisibilityGroup name={t(props.lang, "vis_main_construction")} id="mainConstruction" index={1} layer={[1]} checked={props.checked[1]} callback={props.callback} visibleLayer={props.layer}/>
          <VisibilityGroup name={t(props.lang, "vis_main_roof")} id="mainRoof" index={2} layer={[2, 3, 4, 5]} checked={props.checked[2]} callback={props.callback} visibleLayer={props.layer}>
            <VisibilityElement name={t(props.lang, "vis_roofing")} id="roofing" index={3} layer={2} checked={props.checked[3]} callback={props.callback} visibleLayer={props.layer}/>
            <VisibilityElement name={t(props.lang, "vis_roof_construction")} id="roofConstruction" index={4} layer={3} checked={props.checked[4]} callback={props.callback} visibleLayer={props.layer}/>
            <VisibilityElement name={t(props.lang, "vis_roof_opening")} id="roofOpening" index={5} layer={4} checked={props.checked[5]} callback={props.callback} visibleLayer={props.layer}/>
            <VisibilityElement name={t(props.lang, "vis_roof_ceiling")} id="roofCeiling" index={6} layer={5} checked={props.checked[6]} callback={props.callback} visibleLayer={props.layer}/>
          </VisibilityGroup>
          <VisibilityGroup name={t(props.lang, "vis_main_wall")} id="mainWall" index={7} layer={[6, 7, 8, 9]} checked={props.checked[7]} callback={props.callback} visibleLayer={props.layer}>
            <VisibilityElement name={t(props.lang, "vis_wall_cladding")} id="wallCladding" index={8} layer={6} checked={props.checked[8]} callback={props.callback} visibleLayer={props.layer}/>
            <VisibilityElement name={t(props.lang, "vis_wall_construction")} id="wallConstruction" index={9} layer={7} checked={props.checked[9]} callback={props.callback} visibleLayer={props.layer}/>
            <VisibilityElement name={t(props.lang, "vis_wall_base")} id="wallBase" index={10} layer={8} checked={props.checked[10]} callback={props.callback} visibleLayer={props.layer}/>
            <VisibilityElement name={t(props.lang, "vis_wall_opening")} id="wallOpening" index={11} layer={9} checked={props.checked[11]} callback={props.callback} visibleLayer={props.layer}/>         
          </VisibilityGroup>
          <VisibilityGroup name={t(props.lang, "vis_main_concrete")} id="mainConcrete" index={12} layer={[10, 11]} checked={props.checked[12]} callback={props.callback} visibleLayer={props.layer}>
            <VisibilityElement name={t(props.lang, "vis_concrete_foundation")} id="concreteFoundation" index={13} layer={10} checked={props.checked[13]} callback={props.callback} visibleLayer={props.layer}/>
            <VisibilityElement name={t(props.lang, "vis_concrete_baseplate")} id="concreteBasePlate" index={14} layer={11} checked={props.checked[14]} callback={props.callback} visibleLayer={props.layer}/>
          </VisibilityGroup>
          <VisibilityGroup name={t(props.lang, "vis_main_tank")} id="mainTank" index={15} layer={[12, 13, 14, 15, 18]} checked={props.checked[15]} callback={props.callback} visibleLayer={props.layer}>
            <VisibilityElement name={t(props.lang, "vis_tank_roof")} id="tankRoof" index={16} layer={12} checked={props.checked[16]} callback={props.callback} visibleLayer={props.layer}/>
            <VisibilityElement name={t(props.lang, "vis_tank_ceiling")} id="tankCeiling" index={17} layer={13} checked={props.checked[17]} callback={props.callback} visibleLayer={props.layer}/>
            <VisibilityElement name={t(props.lang, "vis_tank_wall")} id="tankWall" index={18} layer={14} checked={props.checked[18]} callback={props.callback} visibleLayer={props.layer}/>
            <VisibilityElement name={t(props.lang, "vis_tank_column")} id="tankColumn" index={19} layer={15} checked={props.checked[19]} callback={props.callback} visibleLayer={props.layer}/>
          </VisibilityGroup>
          <VisibilityGroup name={t(props.lang, "vis_main_environment")} id="mainEnvironment" index={20} layer={[16, 17]} checked={props.checked[20]} callback={props.callback} visibleLayer={props.layer}>
            <VisibilityElement name={t(props.lang, "vis_environment_ground")} id="environmentGround" index={21} layer={16} checked={props.checked[21]} callback={props.callback} visibleLayer={props.layer}/>
            <VisibilityElement name={t(props.lang, "vis_environment_background")} id="environmentBackground" index={22} layer={17} checked={props.checked[22]} callback={props.callback} visibleLayer={props.layer}/>
          </VisibilityGroup>
        </div>
      </div>}
    </div>
  );
}