import { t } from "./Translation";

export default function Impressum(props: {visible: boolean, setOverlay: (value: string) => void, trans: any}) {
  if (!props.visible) { return null }
  return (
  <div className={`overlay`}>
    <div className="overlayContent">
      <button className="close" onClick={() => props.setOverlay("none")}>close</button>
      <h3>{t(props.trans, "imp_title")}</h3>
      <p>{t(props.trans, "imp_company")}</p>
      <p>{t(props.trans, "imp_street")}</p>
      <p>{t(props.trans, "imp_town")}</p>

      <p className="break">{t(props.trans, "imp_phone")}</p>
      <p>{t(props.trans, "imp_fax")}</p>
      <p>{t(props.trans, "imp_mail")}</p>

      <p className="break">{t(props.trans, "imp_add1")}</p>
      <p>{t(props.trans, "imp_add2")}</p>
      <p>{t(props.trans, "imp_add3")}</p>
      <p>{t(props.trans, "imp_add4")}</p>
    </div>
  </div>
  );
}