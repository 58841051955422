import * as React from 'react';
import NavigationButton from './NavigationButton';
import NorthIcon from '@mui/icons-material/North';
import SouthIcon from '@mui/icons-material/South';
import WestIcon from '@mui/icons-material/West';
import EastIcon from '@mui/icons-material/East';

import "./NavigationButton.css";
import "./LayerButton.css";
import { Views } from './App';
import { t } from './Translation';

export default function ElevationButton(props: {
    icon?: React.ReactElement,
    text: string,
    selected?: boolean,
    callback: (side: string) => void,
    expandedPanel: string,
    setExpandedPanel: (panel: string) => void,
    trans: any,
    views: Views,
  }) {
  
  const getName = (key: string, index: number) => {
    return props.views.view3 === "default" ? `${t(props.trans, "tools_view")} ${index}` : t(props.trans, key);
  }

  return (
    <div onClick={() => props.setExpandedPanel(props.expandedPanel === "elevation" ? "none" : "elevation")} className={`navigationButton ${props.selected ? "selected" : ""} ${props.expandedPanel === "elevation" ? "expanded" : ""}`}>
      {props.icon}
      <p className="iconText">{props.text}</p>
      {props.expandedPanel === "elevation" && <div className='expandContainerElevation'>
        <div onClick={(e) => e.stopPropagation()}>
          <NavigationButton smallText={true} icon={<SouthIcon/>} text={getName(props.views.view1, 3)} callback={() => props.callback("n")} onClick={() => props.setExpandedPanel("none")}/>
          <NavigationButton smallText={true} icon={<WestIcon/>} text={getName(props.views.view4, 4)} callback={() => props.callback("w")} onClick={() => props.setExpandedPanel("none")}/>
          <NavigationButton smallText={true} icon={<EastIcon/>} text={getName(props.views.view3, 2)} callback={() => props.callback("e")} onClick={() => props.setExpandedPanel("none")}/>
          <NavigationButton smallText={true} icon={<NorthIcon/>} text={getName(props.views.view2, 1)} callback={() => props.callback("s")} onClick={() => props.setExpandedPanel("none")}/>
        </div>
      </div>}
    </div>
  );
}