import "./NavigationButton.css";
import MenuIcon from '@mui/icons-material/Menu';
import "./Menu.css";
import { t } from "./Translation";
import { createTheme, MenuItem, Select } from "@mui/material";
import { ThemeProvider } from "@emotion/react";

function Link(props: {text: string, onClick: () => void}) {
  return (
    <li>
      <p className="link" onClick={props.onClick}>{props.text}</p>
    </li>
  )
}

function LanguageSelect(props: {text: string, callBack: (value: string) => void, value: string}) {
  return (
    <li>
      <div id="languageSelection">
      <label id="language">{props.text}:</label>
      <Select
        id="lang-select"
        value={props.value}
        onChange={(ev) => props.callBack(ev.target.value)}
        variant="standard"
        size="small"
        sx={{ m: 1, minWidth: 50 }}
      >
        <MenuItem sx={{ fontSize: 14}} value={"de"}>de</MenuItem>
        <MenuItem sx={{ fontSize: 14}} value={"en"}>en</MenuItem>
        <MenuItem sx={{ fontSize: 14}} value={"it"}>it</MenuItem>
        <MenuItem sx={{ fontSize: 14}} value={"fr"}>fr</MenuItem>
        <MenuItem sx={{ fontSize: 14}} value={"uk"}>uk</MenuItem>
        <MenuItem sx={{ fontSize: 14}} value={"ro"}>ro</MenuItem>
        <MenuItem sx={{ fontSize: 14}} value={"hu"}>hu</MenuItem>
        <MenuItem sx={{ fontSize: 14}} value={"lt"}>lt</MenuItem>
        <MenuItem sx={{ fontSize: 14}} value={"pl"}>pl</MenuItem>
        <MenuItem sx={{ fontSize: 14}} value={"lv"}>lv</MenuItem>
        <MenuItem sx={{ fontSize: 14}} value={"cs"}>cs</MenuItem>
        <MenuItem sx={{ fontSize: 14}} value={"es"}>es</MenuItem>
        <MenuItem sx={{ fontSize: 14}} value={"ru"}>ru</MenuItem>
      </Select>
      </div>
  </li>
  );
}

export default function Menu(props: {
    setExpandedPanel: (value: string) => void,
    expandedPanel: string,
    trans: Object,
    setOverlayPanel: (value: string) => void,
    setLanguage: (value: string) => void,
  }) {
  
  const theme = createTheme({
    palette: {
      primary: {
        main: "#ffdd00",
      },
    }
  });
  
  return (
    <ThemeProvider theme={theme}>
    <div style={{position: "absolute", left: 0, top: "-5px"}} onClick={() => props.setExpandedPanel(props.expandedPanel === "menu" ? "none" : "menu")} className={`navigationButton ${props.expandedPanel === "menu" ? "expanded" : ""} menuButton`}>
      <MenuIcon fontSize="large"/>
      {props.expandedPanel === "menu" && <div className='expandContainerMenu'>
        <div onClick={(e) => e.stopPropagation()}>
          <ul>
            <Link text={t(props.trans, "menu_help")} onClick={() => {props.setOverlayPanel("help"); props.setExpandedPanel("none")}}/>
            <Link text={t(props.trans, "menu_about")} onClick={() => {props.setOverlayPanel("impressum"); props.setExpandedPanel("none")}}/>
            <LanguageSelect text={t(props.trans, "menu_language")} callBack={props.setLanguage} value={(props.trans as any).lang}/>
            <p><span>&copy;</span> Wolf System GmbH {new Date().getFullYear()}</p>
          </ul>
        </div>
      </div>}
    </div>
    </ThemeProvider>
  );
}